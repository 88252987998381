import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useVisitSF from 'content-queries/mx/visit-sf'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import {
  AffiliatesReviews,
  HowItWorks,
  MainBanner,
  MainContent,
  TrustedMedia,
} from 'mx/components/new'
import Layout from 'mx/layout'

const VisitSFPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Visit San Francisco Studio',
    })
  }, [])

  const { data } = useVisitSF()
  const [
    {
      node: {
        sectionModules: [
          hero,
          reviews,
          media,
          timeless,
          performance,
          innovation,
          howItWorks,
          premium,
          map,
          notIn,
        ],
      },
    },
  ] = data

  return (
    <Layout header={false}>
      <AutomaticPopup />
      <MainContent header dark data={hero} />
      <AffiliatesReviews
        data={reviews}
        classname={tw`!bg-gray-light pt-8 lg:pt-12`}
      />
      <TrustedMedia
        border
        data={media}
        title={'As Seen In'}
        classname={tw`!bg-gray-light pt-16`}
      />
      <MainContent inline dark data={timeless} classname={tw`mt-20 lg:mt-32`} />
      <MainContent inline data={performance} classname={tw`mt-20 lg:mt-32`} />
      <MainContent inline data={innovation} classname={tw`mt-20 lg:mt-32`} />
      <HowItWorks
        data={howItWorks}
        classname={tw`!bg-gray-100 mt-20 lg:mt-32`}
      />
      <MainContent dark data={premium} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent
        inline
        dark
        data={map}
        classname={tw`!bg-brown-medium mt-20 lg:mt-32`}
      />
      <MainBanner data={notIn} classname={tw`!bg-brown-light mt-20 lg:mt-32`} />
    </Layout>
  )
}
export default VisitSFPage

export const Head = () => {
  const { data } = useVisitSF()
  const [
    {
      node: { image },
    },
  ] = data

  return (
    <SEO
      description="Feel the quality of our European cabinetry and speak with our expert consultants at our San Francisco Studio."
      path="/visit-sf"
      title="San Francisco Studio"
      image={
        image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback
          ?.src
      }
    />
  )
}
